import React from 'react'

function CopyrightFooter() {
  return (
    <div className='copyright-box'>
    <p>STANLEY  2023. All Rights Reserved</p>
</div>
  )
}

export default CopyrightFooter